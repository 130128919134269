.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.selected-view {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  color: white;
  max-width: 480px;
  margin: auto;
}

.selected-view {
  grid-column: 2;
  margin: 0;
}

.view-container {
  display: flex;
}

.right-side-filler {
  grid-column: 3;
}

.view-selector {
  display:flex;
  flex-direction: column;
  justify-content: right;
  margin-left: auto;
  width: 100%;
  grid-column: 1;
}

.view-button {
  background-color: #545b6b;
  font-size: 18px;
  color: white;
  padding: 10px;
  padding-right: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 100px;
  margin-left: 35%;
  width: 100%;
}

@media (max-width: 750px) {
  .view-selector { 
    grid-column: 2;
    flex-direction: row;
    margin-right: 10%;
    margin-bottom: 0px;
  }

  .view-button {
    margin-right: 15px;
    margin-bottom: 0px;
    margin-left: 0px;
    height: auto;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }
}


.view-button:hover {
  background-color: #6d798f;
}

.view-button.button-selected {
  margin-bottom: 0;
  background-color: #383e49;
}

.view-button.button-selected:hover {
  background-color: #4f596b;
}




