#letters-game {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 24px;
    color: white;
    max-width: 480px;
    margin: auto;
  }

  #letters-game-display, #letters-game-control-panel {
    background-color: #383e49;
    border-radius: 4px;
  }
  
  #letters-display {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    background-color: #444b58;
  }
  
  .letter {
    background-color: rgb(69, 143, 208);
    width: 40px;
    text-align: center;
    line-height: 35px;
    min-height: 36px;
    font-weight: bolder;
  }
  
  .letter:hover {
    background-color: rgb(72, 156, 230);
  }
  
  
  #game-timer {
    display: block;
    text-align: center;
    align-content: center;
    justify-content: center;
    margin: auto;
  }
  
  #word-entry {
    height: 30px;
    width: 60%;
    font-size: 16px;
    margin: 2px;
  }
  
  .consonant-vowel-selection, #word-entry-div, #round-controller {
    margin: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
  }
  
  .game-button {
    height: 35px;
    width: 150px;
    font-size: 16px;
    color: rgb(32, 32, 32);
    background-color: rgb(193, 202, 209);
    border-radius: 1px;
    border-style: none;
    font-weight: bold;
    margin: 2px;
  }
  
  .game-button:hover {
    color: rgb(48,48,48);
    background-color: rgb(215, 225, 233);
  }
  
  .game-button:active {
    color: rgb(24,24,24);
    background-color: rgb(215, 225, 260);
    border-bottom: #282c34;
  }
  
  .game-button:disabled {
    color: rgb(124,124,124);
    background-color: rgb(193, 202, 209);
    border-bottom: #282c34;
  }
  
  #backspace {
    width: 50px;
  }
  
  #words-panel {
    display: flex;
    font-size: 12pt;
  }
  
  #saved-words, #results-display {
    width: 50%;
    margin: 10px;
  }
  
  .word-list > table {
    font-family: monospace;
    letter-spacing: 1px;
  }
  
  .word-list td.pts {
    text-align: right;
    width: 5%;
  }
  
  @media (max-width: 420px) {
    .letter {
      margin: 0 1px;
    }
  
    .game-button {
      font-size: 14px;
    }
  }