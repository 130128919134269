.ladder-pair{
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  background-color: #545b6b;
  margin: 1px;
  padding: 2px;
  padding-left: 3px;
}

.ladder-getter {
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 20px;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.ladder-show {
  font-family: monospace;
  font-size: 16px;
  padding-left: 10px;
}

.filter-multi-select {
  max-height: 30px;
  margin: 3px;
}

#ladder-explorer {
  width: 480px;
  background-color: #383e49;
}

#ladder-filters {
  display: flex;
}

#ladder-filters > div {
  margin: 5px;
}

.ladder-filter-text {
  margin: 5px;
}
