#word-entry-div {
    width: 460px;
    background-color: #383e49;
  }
  
  .word-entry-container {
    background-color: #383e49;
  }
  
  .word-list-container {
    border-style: solid;
    border-width: 0 0 1px 0px;
    border-color: #282c34;
    background-color: #47505f;
  }
  
  .word-list-text {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
  
  #longest-word {
    font-size: 18px;
  }
  
  .word-list-text {
    font-size: 18px;
  }
  
  li.word-list-item {
    font-size: 18px;
    padding: 1px 0 1px 0;
  }
  
  ul.word-list {
    margin-top: 2px;
    margin-bottom: 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #383e49;
  }
  
  .probe-header {
    text-align: center;
    padding-top: 15px;
  }